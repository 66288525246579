import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

import SmartFeedFiltersComponent from "../components/SmartFeedWithFilters_WT"

require("es6-promise").polyfill()

export default function HomepagePagePage({
  pageContext: { integratorData, contentMetaData, contentDataData },
  props,
}) {
  const [vacancyLoading, setVacancyLoading] = useState(true)

  return (
    <Layout {...props}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{contentMetaData.seoTitle}</title>
        <meta name="description" content={contentMetaData.metaDescription} />
      </Helmet>

      <SmartFeedFiltersComponent
        setVacancyLoading={setVacancyLoading}
        vacancyLoading={vacancyLoading}
        apiKey={"2KE5dbfuV0FDuOYHEbrfX254tBDfXQJbUwx9EXKCNOE"}
        group={false}
        gdprKey=""
        CompanyGroupName="Woodland Trust"
        VacancyPageBaseURL="https://jobs.woodlandtrust.org.uk/vacancies/vacancy/"
        ReturnToSearchURL="https://jobs.woodlandtrust.org.uk/vacancies/"
        contentDataData={contentDataData}
      />
    </Layout>
  )
}
